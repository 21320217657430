$color-primary: #19AD79;
$color-secondary: #000000;

.iql-loading-parent--relative {
  position: relative !important;
}

.iql-loading-parent--hidden {
  overflow: hidden !important;
}

.iql-loading-mask {
  position: absolute;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;

  &.is-fullscreen {
    position: fixed;

    .iql-loading-spinner {
      margin-top: -25px;

      .circular {
        height: 50px;
        width: 50px;
      }
    }
  }
}

.iql-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;

  .iql-loading-text {
    color: $color-secondary;
    margin: 3px 0;
    font-size: 14px;
  }

  .circular {
    height: 50px;
    width: 50px;
    -webkit-animation: loading-rotate 2s linear infinite;
    animation: loading-rotate 2s linear infinite;
  }

  .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: $color-primary;
    stroke-linecap: round;
  }

  i {
    color: $color-primary;
  }
}

.iql-loading-fade-enter,
.iql-loading-fade-leave-active {
  opacity: 0;
}

@-webkit-keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}
